.vert {
  padding: 5px !important;
  width: 50% !important;
  height: auto !important;
  border: 1px solid #00a289 !important;
  border-radius: 5px !important;
}
.clearfixViolet {
  background-color: #7564ac;
  color: white;
}
.clearfixViolet:hover {
  background-color: white;
  color: #7564ac;
}
