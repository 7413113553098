.retour {
  z-index: 20;
  min-height: auto;
  color: #0099ff;
}
.retour__content {
  display: flex;
  flex-direction: column;
  min-height: auto;
  justify-content: center;
  align-items: center;
}
.retour__contentTitle {
  display: flex;
  flex-direction: column;
  min-height: auto;
  justify-content: center;
  align-items: center;
}
.retour__contentLive {
  width: 50%;
}
.retour__header {
  display: flex;
  justify-content: space-around;
}
.link-prepra {
  font-size: 1rem;
}
.information {
  font-size: 0.8rem;
}
@media (max-width: 1230px) {
  .link-prepra {
    font-size: 0.5rem;
  }
  .information {
    font-size: 0.5rem;
  }
}
@media (max-width: 1100px) {
}
@media (max-width: 900px) {
  .retour__contentLive {
    width: 100%;
  }
  h2 {
    font-size: 20px;
  }
}
@media (max-width: 868px) {
}
@media (max-width: 380px) {
}
