.prepa {
  z-index: 20;
  min-height: auto;
  color: #0099ff;
}
.prepa__header {
  display: flex;
  justify-content: space-between;
  margin-left: 50px;
  margin-right: 50px;
}
.prepa__link {
  display: flex;
  min-height: auto;
  justify-content: space-between;
  align-items: center;
}
.prepa__content {
  display: flex;
  flex-direction: column;
  min-height: auto;
  justify-content: center;
  align-items: center;
}
.prepa__actu {
  color: #ccc;
  width: 50%;
  padding: 20px;
  border: 1px solid #3499fe;
  border-radius: 5px;
}
.prepa__actu > ul > li {
  margin-top: 20px;
  text-align: justify;
  text-justify: inter-word;
  list-style: none;
}
.prepa__video {
  padding: 5px;
  width: 50%;
  height: auto;
  border: 1px solid #3499fe;
  border-radius: 5px;
}
.prepa__footer {
  color: #ccc;
  text-align: justify;
  width: 50%;
}

.video {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.logodark {
  width: 250px;
}
.prepa__title {
  font-size: 1.5rem;
  color: #3499fe;
}

.prepa__details {
  display: flex;
  align-items: center;
  justify-content: start;
}

.prepa_image {
  display: block;
  width: 60px;
}

.prepa__text {
  padding: 10px;
  font-size: 1rem;
}
.count_text {
  font-size: 20px !important;
}
.instagr {
  width: 200px !important;
  background-color: #00a289;
  cursor: pointer !important;
  color: white;
  margin-left: 0;
}
.replay-text {
  margin-top: 0px;
  font-size: 1.5rem;
}
.prepa__linkScroll {
  color: #3499fe;
}

.prepa__linkScroll:hover {
  cursor: pointer;
}
.livebtn {
  font-size: 1rem;
  width: 40%;
}
@media (max-width: 1230px) {
}
@media (max-width: 1100px) {
  .livebtn {
    font-size: 0.55rem;
    width: 80%;
  }
}
@media (max-width: 900px) {
  .video {
    margin: 0px;
  }
  .prepa__video {
    padding: 0px;
  }
  .prepa__video {
    width: 100%;
  }
  .prepa__actu,
  .prepa__footer {
    width: 100%;
  }
  .prepa__title {
    font-size: 20px;
  }
  .logodark {
    width: 200px;
  }
  .prepa__title {
    font-size: 1rem;
  }
  .prepa__text {
    font-size: 13px;
  }

  .prepa__actu > ul > li {
    overflow: hidden;
  }

  .instagr {
    width: auto;
    margin-left: 20px;
  }

  .prepa__link {
    margin-left: 30px;
  }
  .replay-text {
    margin-top: 20px;
    font-size: 1rem;
  }
}
@media (max-width: 868px) {
}
@media (max-width: 380px) {
}
