html {
  --color-text: white;
  --color-text2: #cccc;
  --color-background: black;
  --color-primary: rebeccapurple;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-background);
  cursor: none;
  /* cursor: url("/src/components/styles/flame12345.gif"), move; */
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.cursor {
  position: absolute;
  z-index: -1;
  width: 40px;
  height: 60px;
  background: transparent url("/src/components/styles/flame12345.gif") 0 0
    no-repeat;
}
/*html,body{cursor:url("https://mon-aventure-darkroom.com/kit/wp-content/uploads/2021/09/flame12345.gif"), auto;}*/