.instagram-media {
  background: #fff;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5), 0 1px 10px 0 rgba(0, 0, 0, 0.15);
  margin: 1px;
  max-width: 540px;
  min-width: 326px;
  padding: 0;
  width: 99.375%;
  width: -webkit-calc(100% - 2px);
  width: calc(100% - 2px);
}
.div_01 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.div_02 {
  background-color: #f4f4f4;
  border-radius: 50%;
  flex-grow: 0;
  height: 40px;
  margin-right: 14px;
  width: 40px;
}
.div_03 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}
.div_04 {
  background-color: #f4f4f4;
  border-radius: 4px;
  flex-grow: 0;
  height: 14px;
  margin-bottom: 6px;
  width: 100px;
}
.div_05 {
  background-color: #f4f4f4;
  border-radius: 4px;
  flex-grow: 0;
  height: 14px;
  width: 60px;
}
.div-06 {
  padding: 19% 0;
}
.div_07 {
  display: block;
  height: 50px;
  margin: 0 auto 12px;
  width: 50px;
}
.div_08 {
  padding-top: 8px;
}
.div_09 {
  color: #3897f0;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 550;
  line-height: 18px;
}
.div_10 {
  padding: 12.5% 0;
}
.div_11 {
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
  align-items: center;
}
.div_12 {
  background-color: #f4f4f4;
  border-radius: 50%;
  height: 12.5px;
  width: 12.5px;
  transform: translateX(0px) translateY(7px);
}
.div_13 {
  background-color: #f4f4f4;
  height: 12.5px;
  transform: rotate(-45deg) translateX(3px) translateY(1px);
  width: 12.5px;
  flex-grow: 0;
  margin-right: 14px;
  margin-left: 2px;
}
.div-14 {
  background-color: #f4f4f4;
  border-radius: 50%;
  height: 12.5px;
  width: 12.5px;
  transform: translateX(9px) translateY(-18px);
}
.div_15 {
  margin-left: 8px;
}
.div_16 {
  background-color: #f4f4f4;
  border-radius: 50%;
  flex-grow: 0;
  height: 20px;
  width: 20px;
}
.div_17 {
  width: 0;
  height: 0;
  border-top: 2px solid transparent;
  border-left: 6px solid #f4f4f4;
  border-bottom: 2px solid transparent;
  transform: translateX(16px) translateY(-4px) rotate(30deg);
}

.div_18 {
  margin-left: auto;
}
.div_19 {
  width: 0px;
  border-top: 8px solid #f4f4f4;
  border-right: 8px solid transparent;
  transform: translateY(16px);
}
.div_20 {
  background-color: #f4f4f4;
  flex-grow: 0;
  height: 12px;
  width: 16px;
  transform: translateY(-4px);
}
.div_21 {
  width: 0;
  height: 0;
  border-top: 8px solid #f4f4f4;
  border-left: 8px solid transparent;
  transform: translateY(-4px) translateX(8px);
}
.div_22 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin-bottom: 24px;
}
.div_23 {
  background-color: #f4f4f4;
  border-radius: 4px;
  flex-grow: 0;
  height: 14px;
  margin-bottom: 6px;
  width: 224px;
}
.div_24 {
  background-color: #f4f4f4;
  border-radius: 4px;
  flex-grow: 0;
  height: 14px;
  width: 144px;
}
.div_25 {
  color: #c9c8cd;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0;
  margin-top: 8px;
  overflow: hidden;
  padding: 8px 0 7px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.div_26 {
  color: #c9c8cd;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
  text-decoration: none;
}
.div_27 {
  background: #ffffff;
  line-height: 0;
  padding: 0 0;
  text-align: center;
  text-decoration: none;
  width: 100%;
}
.div_28 {
  padding: "16px";
}
