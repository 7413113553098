/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
:root {
  --hue-color: 204;
  --scroll-bar-color: hsl(var(--hue-color), 12%, 90%);
  --scroll-thumb-color: hsl(var(--hue-color), 100%, 80%);
  --text-color-light: hsl(var(--hue-color), 8%, 65%);
}

.App {
  max-width: 100%;
  min-height: 100vh;
}
#shark {
  width: 500px;
  height: 500px;
  background-image: radial-gradient(white, black, transparent);
  position: absolute;
  margin-left: 0px;
  margin-bottom: 0px;
  z-index: -1;
}
.logo {
  position: absolute;
  z-index: -1;
}
.player-wrapper {
  z-index: 130;
  position: relative;
  /* padding-top: 56.25%;  */
  /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.logodark {
  width: 150px !important;
}

.logoewc {
  width: 120px !important;
}
/* .instagr {
  position: absolute;
  z-index: 20000;
} */
@media (max-width: 1230px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 900px) {
  .logodark {
    width: 100px !important;
  }
  .logoewc {
    width: 100px !important;
  }
}
@media (max-width: 868px) {
}
@media (max-width: 380px) {
}
/*========== SCROLL BAR ==========*/
::-webkit-scrollbar {
  width: 0.6rem;
  background-color: var(--scroll-bar-color);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: var(--text-color-light);
  border-radius: 0.5rem;
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--text-color-light);
}
