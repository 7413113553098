.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  z-index: 30;
  position: absolute;
  top: 0;
  left: 0;
}
.titre {
  margin-top: 0px;
  font-size: 1.5rem;
}
@media (max-width: 1230px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 900px) {
  .titre {
    margin-top: 20px;
    font-size: 1rem;
  }
}
@media (max-width: 868px) {
}
@media (max-width: 380px) {
}
