html {
  --color-text: white;
  --color-text2: #cccc;
  --color-text3: #3499fe;
}

.count {
  color: var(--color-text3);
  font-size: 50px;
  font-weight: 550;
}
.count_prepa {
  font-size: 2em;
}

.time {
  display: flex;
  flex-direction: column;
  position: sticky;
  bottom: 0;
  height: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.home {
  color: var(--color-text2);
  overflow-x: hidden;
  min-height: 100vh;
  z-index: 10;
}
.home__video {
  z-index: 30;
  position: relative;
  width: 100%;
  height: auto;
  margin-right: -200px;
}

.home__form {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  border-radius: 5px;
}
.home__content {
  display: flex;
  flex-wrap: nowrap;
  height: auto;
}
h3 {
  font-size: 2.5em;
}
.screenreplace {
  width: 90%;
}
@media (max-width: 1230px) {
}
@media (max-width: 1100px) {
  .home__content {
    flex-wrap: wrap;
  }
  .home__video {
    /* background-color: red; */
    height: 300px;
    margin-right: 0px;
  }
  h3 {
    font-size: 1.2em;
  }
  .screenreplace {
    width: 100%;
  }
}
@media (max-width: 900px) {
  .count {
    font-size: 1.5rem;
    font-weight: bold;
  }
}
@media (max-width: 868px) {
}
@media (max-width: 380px) {
}
#_form_146_ {
  background: transparent !important;
  color: white !important;
}
.modal-content {
  background: rgba(0, 0, 0, 0.8) !important;
  border: 1px solid #3499fe;
  border-radius: 15px;
}
.card {
  box-shadow: rgba(223, 215, 250, 0.2) 0px 7px 29px 0px !important;
}
.form-control {
  height: 50px !important;
  font-size: 15px !important;
}

#_notification {
  color: rgb(52, 153, 254);
  justify-content: center;
  margin-bottom: 15px;
  font-size: 20px;
}

#_notification2 {
  color: rgb(52, 153, 254);
  justify-content: right;
  margin-top: 15px;
  font-size: 11px;
}
