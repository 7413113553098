html {
  --color-text: white;
  --color-text2: #cccc;
  --color-background: black;
  --color-primary: rebeccapurple;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  background-color: var(--color-background);
  cursor: none;
  /* cursor: url("/src/components/styles/flame12345.gif"), move; */
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.cursor {
  position: absolute;
  z-index: -1;
  width: 40px;
  height: 60px;
  background: transparent url(/static/media/flame12345.6230c86f.gif) 0 0
    no-repeat;
}
/*html,body{cursor:url("https://mon-aventure-darkroom.com/kit/wp-content/uploads/2021/09/flame12345.gif"), auto;}*/
/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
:root {
  --hue-color: 204;
  --scroll-bar-color: hsl(var(--hue-color), 12%, 90%);
  --scroll-thumb-color: hsl(var(--hue-color), 100%, 80%);
  --text-color-light: hsl(var(--hue-color), 8%, 65%);
}

.App {
  max-width: 100%;
  min-height: 100vh;
}
#shark {
  width: 500px;
  height: 500px;
  background-image: radial-gradient(white, black, transparent);
  position: absolute;
  margin-left: 0px;
  margin-bottom: 0px;
  z-index: -1;
}
.logo {
  position: absolute;
  z-index: -1;
}
.player-wrapper {
  z-index: 130;
  position: relative;
  /* padding-top: 56.25%;  */
  /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.logodark {
  width: 150px !important;
}

.logoewc {
  width: 120px !important;
}
/* .instagr {
  position: absolute;
  z-index: 20000;
} */
@media (max-width: 1230px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 900px) {
  .logodark {
    width: 100px !important;
  }
  .logoewc {
    width: 100px !important;
  }
}
@media (max-width: 868px) {
}
@media (max-width: 380px) {
}
/*========== SCROLL BAR ==========*/
::-webkit-scrollbar {
  width: 0.6rem;
  background-color: hsl(204, 12%, 90%);
  background-color: var(--scroll-bar-color);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: hsl(204, 8%, 65%);
  background-color: var(--text-color-light);
  border-radius: 0.5rem;
}
::-webkit-scrollbar-thumb:hover {
  background-color: hsl(204, 8%, 65%);
  background-color: var(--text-color-light);
}

@keyframes ldio-l3be1qdzf3j {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ldio-l3be1qdzf3j div {
  left: 94px;
  top: 48px;
  position: absolute;
  animation: ldio-l3be1qdzf3j linear 1s infinite;
  background: #3499fe;
  width: 12px;
  height: 24px;
  border-radius: 6px / 12px;
  transform-origin: 6px 52px;
}
.ldio-l3be1qdzf3j div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
  background: #3499fe;
}
.ldio-l3be1qdzf3j div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
  background: #3499fe;
}
.ldio-l3be1qdzf3j div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: #3499fe;
}
.ldio-l3be1qdzf3j div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: #3499fe;
}
.ldio-l3be1qdzf3j div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: #3499fe;
}
.ldio-l3be1qdzf3j div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: #3499fe;
}
.ldio-l3be1qdzf3j div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: #3499fe;
}
.ldio-l3be1qdzf3j div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: #3499fe;
}
.ldio-l3be1qdzf3j div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: #3499fe;
}
.ldio-l3be1qdzf3j div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: #3499fe;
}
.ldio-l3be1qdzf3j div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: #3499fe;
}
.ldio-l3be1qdzf3j div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #3499fe;
}
.loadingio-spinner-spinner-3cruluoik08 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: rgba(NaN, NaN, NaN, 0);
}
.ldio-l3be1qdzf3j {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-l3be1qdzf3j div {
  box-sizing: content-box;
}
.rct {
  display: flex;
  flex-direction: column;
}
/* generated by https://loading.io/ */
.row-content {
  color: #3499fe;
}
.table {
  color: #3499fe;
}

.rct {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .tab1 input {
    width: 100%;
  }
}
.MuiTabs-flexContainer {
  justify-content: "center" !important;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  z-index: 30;
  position: absolute;
  top: 0;
  left: 0;
}
.titre {
  margin-top: 0px;
  font-size: 1.5rem;
}
@media (max-width: 1230px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 900px) {
  .titre {
    margin-top: 20px;
    font-size: 1rem;
  }
}
@media (max-width: 868px) {
}
@media (max-width: 380px) {
}

html {
  --color-text: white;
  --color-text2: #cccc;
  --color-text3: #3499fe;
}

.count {
  color: #3499fe;
  color: var(--color-text3);
  font-size: 50px;
  font-weight: 550;
}
.count_prepa {
  font-size: 2em;
}

.time {
  display: flex;
  flex-direction: column;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  height: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.home {
  color: #cccc;
  color: var(--color-text2);
  overflow-x: hidden;
  min-height: 100vh;
  z-index: 10;
}
.home__video {
  z-index: 30;
  position: relative;
  width: 100%;
  height: auto;
  margin-right: -200px;
}

.home__form {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  border-radius: 5px;
}
.home__content {
  display: flex;
  flex-wrap: nowrap;
  height: auto;
}
h3 {
  font-size: 2.5em;
}
.screenreplace {
  width: 90%;
}
@media (max-width: 1230px) {
}
@media (max-width: 1100px) {
  .home__content {
    flex-wrap: wrap;
  }
  .home__video {
    /* background-color: red; */
    height: 300px;
    margin-right: 0px;
  }
  h3 {
    font-size: 1.2em;
  }
  .screenreplace {
    width: 100%;
  }
}
@media (max-width: 900px) {
  .count {
    font-size: 1.5rem;
    font-weight: bold;
  }
}
@media (max-width: 868px) {
}
@media (max-width: 380px) {
}
#_form_146_ {
  background: transparent !important;
  color: white !important;
}
.modal-content {
  background: rgba(0, 0, 0, 0.8) !important;
  border: 1px solid #3499fe;
  border-radius: 15px;
}
.card {
  box-shadow: rgba(223, 215, 250, 0.2) 0px 7px 29px 0px !important;
}
.form-control {
  height: 50px !important;
  font-size: 15px !important;
}

#_notification {
  color: rgb(52, 153, 254);
  justify-content: center;
  margin-bottom: 15px;
  font-size: 20px;
}

#_notification2 {
  color: rgb(52, 153, 254);
  justify-content: right;
  margin-top: 15px;
  font-size: 11px;
}

.prepa {
  z-index: 20;
  min-height: auto;
  color: #0099ff;
}
.prepa__header {
  display: flex;
  justify-content: space-between;
  margin-left: 50px;
  margin-right: 50px;
}
.prepa__link {
  display: flex;
  min-height: auto;
  justify-content: space-between;
  align-items: center;
}
.prepa__content {
  display: flex;
  flex-direction: column;
  min-height: auto;
  justify-content: center;
  align-items: center;
}
.prepa__actu {
  color: #ccc;
  width: 50%;
  padding: 20px;
  border: 1px solid #3499fe;
  border-radius: 5px;
}
.prepa__actu > ul > li {
  margin-top: 20px;
  text-align: justify;
  text-justify: inter-word;
  list-style: none;
}
.prepa__video {
  padding: 5px;
  width: 50%;
  height: auto;
  border: 1px solid #3499fe;
  border-radius: 5px;
}
.prepa__footer {
  color: #ccc;
  text-align: justify;
  width: 50%;
}

.video {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.logodark {
  width: 250px;
}
.prepa__title {
  font-size: 1.5rem;
  color: #3499fe;
}

.prepa__details {
  display: flex;
  align-items: center;
  justify-content: start;
}

.prepa_image {
  display: block;
  width: 60px;
}

.prepa__text {
  padding: 10px;
  font-size: 1rem;
}
.count_text {
  font-size: 20px !important;
}
.instagr {
  width: 200px !important;
  background-color: #00a289;
  cursor: pointer !important;
  color: white;
  margin-left: 0;
}
.replay-text {
  margin-top: 0px;
  font-size: 1.5rem;
}
.prepa__linkScroll {
  color: #3499fe;
}

.prepa__linkScroll:hover {
  cursor: pointer;
}
.livebtn {
  font-size: 1rem;
  width: 40%;
}
@media (max-width: 1230px) {
}
@media (max-width: 1100px) {
  .livebtn {
    font-size: 0.55rem;
    width: 80%;
  }
}
@media (max-width: 900px) {
  .video {
    margin: 0px;
  }
  .prepa__video {
    padding: 0px;
  }
  .prepa__video {
    width: 100%;
  }
  .prepa__actu,
  .prepa__footer {
    width: 100%;
  }
  .prepa__title {
    font-size: 20px;
  }
  .logodark {
    width: 200px;
  }
  .prepa__title {
    font-size: 1rem;
  }
  .prepa__text {
    font-size: 13px;
  }

  .prepa__actu > ul > li {
    overflow: hidden;
  }

  .instagr {
    width: auto;
    margin-left: 20px;
  }

  .prepa__link {
    margin-left: 30px;
  }
  .replay-text {
    margin-top: 20px;
    font-size: 1rem;
  }
}
@media (max-width: 868px) {
}
@media (max-width: 380px) {
}

.instagram-media {
    background: #fff;
    border: 0;
    border-radius: 3px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5), 0 1px 10px 0 rgba(0, 0, 0, 0.15);
    margin: 1px;
    max-width: 540px;
    min-width: 326px;
    padding: 0;
    width: 99.375%;
    width: calc(100% - 2px);
  }
  .div_01 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .div_02 {
    background-color: #f4f4f4;
    border-radius: 50%;
    flex-grow: 0;
    height: 40px;
    margin-right: 14px;
    width: 40px;
  }
  .div_03 {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
  }
  .div_04 {
    background-color: #f4f4f4;
    border-radius: 4px;
    flex-grow: 0;
    height: 14px;
    margin-bottom: 6px;
    width: 100px;
  }
  .div_05 {
    background-color: #f4f4f4;
    border-radius: 4px;
    flex-grow: 0;
    height: 14px;
    width: 60px;
  }
  .div-06 {
    padding: 19% 0;
  }
  .div_07 {
    display: block;
    height: 50px;
    margin: 0 auto 12px;
    width: 50px;
  }
  .div_08 {
    padding-top: 8px;
  }
  .div_09 {
    color: #3897f0;
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 550;
    line-height: 18px;
  }
  .div_10 {
    padding: 12.5% 0;
  }
  .div_11 {
    display: flex;
    flex-direction: row;
    margin-bottom: 14px;
    align-items: center;
  }
  .div_12 {
    background-color: #f4f4f4;
    border-radius: 50%;
    height: 12.5px;
    width: 12.5px;
    transform: translateX(0px) translateY(7px);
  }
  .div_13 {
    background-color: #f4f4f4;
    height: 12.5px;
    transform: rotate(-45deg) translateX(3px) translateY(1px);
    width: 12.5px;
    flex-grow: 0;
    margin-right: 14px;
    margin-left: 2px;
  }
  .div-14 {
    background-color: #f4f4f4;
    border-radius: 50%;
    height: 12.5px;
    width: 12.5px;
    transform: translateX(9px) translateY(-18px);
  }
  .div_15 {
    margin-left: 8px;
  }
  .div_16 {
    background-color: #f4f4f4;
    border-radius: 50%;
    flex-grow: 0;
    height: 20px;
    width: 20px;
  }
  .div_17 {
    width: 0;
    height: 0;
    border-top: 2px solid transparent;
    border-left: 6px solid #f4f4f4;
    border-bottom: 2px solid transparent;
    transform: translateX(16px) translateY(-4px) rotate(30deg);
  }
  
  .div_18 {
    margin-left: auto;
  }
  .div_19 {
    width: 0px;
    border-top: 8px solid #f4f4f4;
    border-right: 8px solid transparent;
    transform: translateY(16px);
  }
  .div_20 {
    background-color: #f4f4f4;
    flex-grow: 0;
    height: 12px;
    width: 16px;
    transform: translateY(-4px);
  }
  .div_21 {
    width: 0;
    height: 0;
    border-top: 8px solid #f4f4f4;
    border-left: 8px solid transparent;
    transform: translateY(-4px) translateX(8px);
  }
  .div_22 {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    margin-bottom: 24px;
  }
  .div_23 {
    background-color: #f4f4f4;
    border-radius: 4px;
    flex-grow: 0;
    height: 14px;
    margin-bottom: 6px;
    width: 224px;
  }
  .div_24 {
    background-color: #f4f4f4;
    border-radius: 4px;
    flex-grow: 0;
    height: 14px;
    width: 144px;
  }
  .div_25 {
    color: #c9c8cd;
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0;
    margin-top: 8px;
    overflow: hidden;
    padding: 8px 0 7px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .div_26 {
    color: #c9c8cd;
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 17px;
    text-decoration: none;
  }
  .div_27 {
    background: #ffffff;
    line-height: 0;
    padding: 0 0;
    text-align: center;
    text-decoration: none;
    width: 100%;
  }
  .div_28 {
    padding: "16px";
  }
  
.prepa {
  z-index: 20;
  min-height: auto;
  color: #0099ff;
}
.prepa__header {
  display: flex;
  justify-content: space-between;
  margin-left: 50px;
  margin-right: 50px;
}
.prepa__link {
  display: flex;
  min-height: auto;
  justify-content: space-between;
  align-items: center;
}
.prepa__content {
  display: flex;
  flex-direction: column;
  min-height: auto;
  justify-content: center;
  align-items: center;
}
.prepa__actu {
  color: #ccc;
  width: 50%;
  padding: 20px;
  border: 1px solid #3499fe;
  border-radius: 5px;
}
.prepa__actu > ul > li {
  margin-top: 20px;
  text-align: justify;
  text-justify: inter-word;
  list-style: none;
}
.prepa__video {
  padding: 5px;
  width: 50%;
  height: auto;
  border: 1px solid #3499fe;
  border-radius: 5px;
}
.prepa__footer {
  color: #ccc;
  text-align: justify;
  width: 50%;
}

.video {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.logodark {
  width: 250px;
}
.prepa__title {
  font-size: 1.5rem;
  color: #3499fe;
}

.prepa__details {
  display: flex;
  align-items: center;
  justify-content: start;
}

.prepa_image {
  display: block;
  width: 60px;
}

.prepa__text {
  padding: 10px;
  font-size: 1rem;
}
.count_text {
  font-size: 20px !important;
}
.instagr {
  width: 200px !important;
  background-color: #00a289;
  cursor: pointer !important;
  color: white;
  margin-left: 0;
}
.replay-text {
  margin-top: 0px;
  font-size: 1.5rem;
}
.prepa__linkScroll {
  color: #3499fe;
}

.prepa__linkScroll:hover {
  cursor: pointer;
}
.livebtn {
  font-size: 1rem;
  width: 40%;
}
@media (max-width: 1230px) {
}
@media (max-width: 1100px) {
  .livebtn {
    font-size: 0.55rem;
    width: 80%;
  }
}
@media (max-width: 900px) {
  .video {
    margin: 0px;
  }
  .prepa__video {
    padding: 0px;
  }
  .prepa__video {
    width: 100%;
  }
  .prepa__actu,
  .prepa__footer {
    width: 100%;
  }
  .prepa__title {
    font-size: 20px;
  }
  .logodark {
    width: 200px;
  }
  .prepa__title {
    font-size: 1rem;
  }
  .prepa__text {
    font-size: 13px;
  }

  .prepa__actu > ul > li {
    overflow: hidden;
  }

  .instagr {
    width: auto;
    margin-left: 20px;
  }

  .prepa__link {
    margin-left: 30px;
  }
  .replay-text {
    margin-top: 20px;
    font-size: 1rem;
  }
}
@media (max-width: 868px) {
}
@media (max-width: 380px) {
}

.instagram-media {
  background: #fff;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5), 0 1px 10px 0 rgba(0, 0, 0, 0.15);
  margin: 1px;
  max-width: 540px;
  min-width: 326px;
  padding: 0;
  width: 99.375%;
  width: calc(100% - 2px);
}
.div_01 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.div_02 {
  background-color: #f4f4f4;
  border-radius: 50%;
  flex-grow: 0;
  height: 40px;
  margin-right: 14px;
  width: 40px;
}
.div_03 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}
.div_04 {
  background-color: #f4f4f4;
  border-radius: 4px;
  flex-grow: 0;
  height: 14px;
  margin-bottom: 6px;
  width: 100px;
}
.div_05 {
  background-color: #f4f4f4;
  border-radius: 4px;
  flex-grow: 0;
  height: 14px;
  width: 60px;
}
.div-06 {
  padding: 19% 0;
}
.div_07 {
  display: block;
  height: 50px;
  margin: 0 auto 12px;
  width: 50px;
}
.div_08 {
  padding-top: 8px;
}
.div_09 {
  color: #3897f0;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 550;
  line-height: 18px;
}
.div_10 {
  padding: 12.5% 0;
}
.div_11 {
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
  align-items: center;
}
.div_12 {
  background-color: #f4f4f4;
  border-radius: 50%;
  height: 12.5px;
  width: 12.5px;
  transform: translateX(0px) translateY(7px);
}
.div_13 {
  background-color: #f4f4f4;
  height: 12.5px;
  transform: rotate(-45deg) translateX(3px) translateY(1px);
  width: 12.5px;
  flex-grow: 0;
  margin-right: 14px;
  margin-left: 2px;
}
.div-14 {
  background-color: #f4f4f4;
  border-radius: 50%;
  height: 12.5px;
  width: 12.5px;
  transform: translateX(9px) translateY(-18px);
}
.div_15 {
  margin-left: 8px;
}
.div_16 {
  background-color: #f4f4f4;
  border-radius: 50%;
  flex-grow: 0;
  height: 20px;
  width: 20px;
}
.div_17 {
  width: 0;
  height: 0;
  border-top: 2px solid transparent;
  border-left: 6px solid #f4f4f4;
  border-bottom: 2px solid transparent;
  transform: translateX(16px) translateY(-4px) rotate(30deg);
}

.div_18 {
  margin-left: auto;
}
.div_19 {
  width: 0px;
  border-top: 8px solid #f4f4f4;
  border-right: 8px solid transparent;
  transform: translateY(16px);
}
.div_20 {
  background-color: #f4f4f4;
  flex-grow: 0;
  height: 12px;
  width: 16px;
  transform: translateY(-4px);
}
.div_21 {
  width: 0;
  height: 0;
  border-top: 8px solid #f4f4f4;
  border-left: 8px solid transparent;
  transform: translateY(-4px) translateX(8px);
}
.div_22 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin-bottom: 24px;
}
.div_23 {
  background-color: #f4f4f4;
  border-radius: 4px;
  flex-grow: 0;
  height: 14px;
  margin-bottom: 6px;
  width: 224px;
}
.div_24 {
  background-color: #f4f4f4;
  border-radius: 4px;
  flex-grow: 0;
  height: 14px;
  width: 144px;
}
.div_25 {
  color: #c9c8cd;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0;
  margin-top: 8px;
  overflow: hidden;
  padding: 8px 0 7px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.div_26 {
  color: #c9c8cd;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
  text-decoration: none;
}
.div_27 {
  background: #ffffff;
  line-height: 0;
  padding: 0 0;
  text-align: center;
  text-decoration: none;
  width: 100%;
}
.div_28 {
  padding: "16px";
}

.retour {
  z-index: 20;
  min-height: auto;
  color: #0099ff;
}
.retour__content {
  display: flex;
  flex-direction: column;
  min-height: auto;
  justify-content: center;
  align-items: center;
}
.retour__contentTitle {
  display: flex;
  flex-direction: column;
  min-height: auto;
  justify-content: center;
  align-items: center;
}
.retour__contentLive {
  width: 50%;
}
.retour__header {
  display: flex;
  justify-content: space-around;
}
.link-prepra {
  font-size: 1rem;
}
.information {
  font-size: 0.8rem;
}
@media (max-width: 1230px) {
  .link-prepra {
    font-size: 0.5rem;
  }
  .information {
    font-size: 0.5rem;
  }
}
@media (max-width: 1100px) {
}
@media (max-width: 900px) {
  .retour__contentLive {
    width: 100%;
  }
  h2 {
    font-size: 20px;
  }
}
@media (max-width: 868px) {
}
@media (max-width: 380px) {
}

.modal-content {
  background: rgba(0, 0, 0, 0.8) !important;
  border: 1px solid #3499fe;
  border-radius: 15px;
}
.card {
  box-shadow: rgba(223, 215, 250, 0.2) 0px 7px 29px 0px !important;
}
.form-control {
  height: 50px !important;
  font-size: 15px !important;
}

#_notification {
  color: #00a289;
  justify-content: center;
  margin-bottom: 15px;
  font-size: 20px;
  text-align: justify;
}

#_notification2 {
  color: #00a289;
  justify-content: right;
  margin: 15px 0 15px 0;
  font-size: 12px;
}

#_form_172_ {
  font-size: 14px;
  line-height: 1.6;
  font-family: arial, helvetica, sans-serif;
  margin: 0;
}
#_form_172_ * {
  outline: 0;
}
._form_hide {
  display: none;
  visibility: hidden;
}
._form_show {
  display: block;
  visibility: visible;
}
#_form_172_._form-top {
  top: 0;
}
#_form_172_._form-bottom {
  bottom: 0;
}
#_form_172_._form-left {
  left: 0;
}
#_form_172_._form-right {
  right: 0;
}
#_form_172_ input[type="text"],
#_form_172_ input[type="date"],
#_form_172_ textarea {
  padding: 6px;
  height: auto;
  border: #979797 1px solid;
  border-radius: 4px;
  color: #000 !important;
  font-size: 14px;
  box-sizing: border-box;
}
#_form_172_ textarea {
  resize: none;
}
#_form_172_ ._submit {
  -webkit-appearance: none;
  cursor: pointer;
  font-family: arial, sans-serif;
  font-weight: bolder !important;
  font-size: 1.2rem;
  text-align: center;
  background: #7564ac !important;
  border: 0 !important;
  border-radius: 4px !important;
  color: #fff !important;
  padding: 10px !important;
  width: 100% !important;
  text-transform: uppercase !important;
}
#_form_172_ ._close-icon {
  cursor: pointer;
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/esfkyjh1u_forms-close-dark.png");
  background-repeat: no-repeat;
  background-size: 14.2px 14.2px;
  position: absolute;
  display: block;
  top: 11px;
  right: 9px;
  overflow: hidden;
  width: 16.2px;
  height: 16.2px;
}
#_form_172_ ._close-icon:before {
  position: relative;
}
#_form_172_ ._form-body {
  margin-bottom: 30px;
}
#_form_172_ ._form-image-left {
  width: 150px;
  float: left;
}
#_form_172_ ._form-content-right {
  margin-left: 164px;
}
#_form_172_ ._form-branding {
  color: #fff;
  font-size: 10px;
  clear: both;
  text-align: left;
  margin-top: 30px;
  font-weight: 100;
}
#_form_172_ ._form-branding ._logo {
  display: block;
  width: 130px;
  height: 14px;
  margin-top: 6px;
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/hh9ujqgv5_aclogo_li.png");
  background-size: 130px auto;
  background-repeat: no-repeat;
}
#_form_172_ .form-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
#_form_172_ ._form-label,
#_form_172_ ._form_element ._form-label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}
#_form_172_._dark ._form-branding {
  color: #333;
}
#_form_172_._dark ._form-branding ._logo {
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/jftq2c8s_aclogo_dk.png");
}
#_form_172_ ._form_element {
  position: relative;
  margin-bottom: 10px;
  font-size: 0;
  max-width: 100%;
}
#_form_172_ ._form_element * {
  font-size: 14px;
}
#_form_172_ ._form_element._clear {
  clear: both;
  width: 100%;
  float: none;
}
#_form_172_ ._form_element._clear:after {
  clear: left;
}
#_form_172_ ._form_element input[type="text"],
#_form_172_ ._form_element input[type="date"],
#_form_172_ ._form_element select,
#_form_172_ ._form_element textarea:not(.g-recaptcha-response) {
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-family: inherit;
}
#_form_172_ ._field-wrapper {
  position: relative;
}
#_form_172_ ._inline-style {
  float: left;
}
#_form_172_ ._inline-style input[type="text"] {
  width: 150px;
}
#_form_172_ ._inline-style:not(._clear) + ._inline-style:not(._clear) {
  margin-left: 20px;
}
#_form_172_ ._form_element img._form-image {
  max-width: 100%;
}
#_form_172_ ._form_element ._form-fieldset {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;
}
#_form_172_ ._clear-element {
  clear: left;
}
#_form_172_ ._full_width {
  width: 100%;
}
#_form_172_ ._form_full_field {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}
#_form_172_ input[type="text"]._has_error,
#_form_172_ textarea._has_error {
  border: #f37c7b 1px solid;
}
#_form_172_ input[type="checkbox"]._has_error {
  outline: #f37c7b 1px solid;
}
#_form_172_ ._error {
  display: block;
  position: absolute;
  font-size: 14px;
  z-index: 10000001;
}
#_form_172_ ._error._above {
  padding-bottom: 4px;
  bottom: 39px;
  right: 0;
}
#_form_172_ ._error._below {
  padding-top: 4px;
  top: 100%;
  right: 0;
}
#_form_172_ ._error._above ._error-arrow {
  bottom: 0;
  right: 15px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #f37c7b;
}
#_form_172_ ._error._below ._error-arrow {
  top: 0;
  right: 15px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #f37c7b;
}
#_form_172_ ._error-inner {
  padding: 8px 12px;
  background-color: #f37c7b;
  font-size: 14px;
  font-family: arial, sans-serif;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
}
#_form_172_ ._error-inner._form_error {
  margin-bottom: 5px;
  text-align: left;
}
#_form_172_ ._button-wrapper ._error-inner._form_error {
  position: static;
}
#_form_172_ ._error-inner._no_arrow {
  margin-bottom: 10px;
}
#_form_172_ ._error-arrow {
  position: absolute;
  width: 0;
  height: 0;
}
#_form_172_ ._error-html {
  margin-bottom: 10px;
}
.pika-single {
  z-index: 10000001 !important;
}
#_form_172_ input[type="text"].datetime_date {
  width: 69%;
  display: inline;
}
#_form_172_ select.datetime_time {
  width: 29%;
  display: inline;
  height: 32px;
}
@media all and (min-width: 320px) and (max-width: 667px) {
  ::-webkit-scrollbar {
    display: none;
  }
  #_form_172_ {
    margin: 0;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  #_form_172_ * {
    box-sizing: border-box;
    font-size: 1em;
  }
  #_form_172_ ._form-content {
    margin: 0;
    width: 100%;
  }
  #_form_172_ ._form-inner {
    display: block;
    min-width: 100%;
  }
  #_form_172_ ._form-title,
  #_form_172_ ._inline-style {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
  }
  #_form_172_ ._form-title {
    font-size: 1.2em;
  }
  #_form_172_ ._form_element {
    margin: 0 0 20px;
    padding: 0;
    width: 100%;
  }
  #_form_172_ ._form-element,
  #_form_172_ ._inline-style,
  #_form_172_ input[type="text"],
  #_form_172_ label,
  #_form_172_ p,
  #_form_172_ textarea:not(.g-recaptcha-response) {
    float: none;
    display: block;
    width: 100%;
  }
  #_form_172_ ._row._checkbox-radio label {
    display: inline;
  }
  #_form_172_ ._row,
  #_form_172_ p,
  #_form_172_ label {
    margin-bottom: 0.7em;
    width: 100%;
  }
  #_form_172_ ._row input[type="checkbox"],
  #_form_172_ ._row input[type="radio"] {
    margin: 0 !important;
    vertical-align: middle !important;
  }
  #_form_172_ ._row input[type="checkbox"] + span label {
    display: inline;
  }
  #_form_172_ ._row span label {
    margin: 0 !important;
    width: auto !important;
    width: initial !important;
    vertical-align: middle !important;
  }
  #_form_172_ ._form-image {
    max-width: 100%;
    height: auto !important;
  }
  #_form_172_ input[type="text"] {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    line-height: 1.3em;
    -webkit-appearance: none;
  }
  #_form_172_ input[type="radio"],
  #_form_172_ input[type="checkbox"] {
    display: inline-block;
    width: 1.3em;
    height: 1.3em;
    font-size: 1em;
    margin: 0 0.3em 0 0;
    vertical-align: baseline;
  }
  #_form_172_ button[type="submit"] {
    padding: 20px;
    font-size: 1.5em;
  }
  #_form_172_ ._inline-style {
    margin: 20px 0 0 !important;
  }
}
#_form_172_ {
  position: relative;
  text-align: left;
  /* margin: 25px auto 0; */
  padding: 20px;
  box-sizing: border-box;
  *zoom: 1;
  background: transparent !important;
  border: 0.5px solid #00a289 !important;
  width: 500px;
  border-radius: 0px !important;
  color: #00a289 !important;
  border-radius: 5px !important;
  box-shadow: rgba(223, 215, 250, 0.2) 0px 7px 29px 0px !important;
}
#_form_172_._inline-form,
#_form_172_._inline-form ._form-content,
#_form_172_._inline-form input,
#_form_172_._inline-form ._submit {
  font-family: "IBM Plex Sans", Helvetica, sans-serif, "IBM Plex Sans", arial,
    sans-serif;
}
#_form_172_ ._form-title {
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 0;
}
#_form_172_:before,
#_form_172_:after {
  content: " ";
  display: table;
}
#_form_172_:after {
  clear: both;
}
#_form_172_._inline-style {
  width: auto;
  display: inline-block;
}
#_form_172_._inline-style input[type="text"],
#_form_172_._inline-style input[type="date"] {
  padding: 10px 12px;
}
#_form_172_._inline-style button._inline-style {
  position: relative;
  top: 27px;
}
#_form_172_._inline-style p {
  margin: 0;
}
#_form_172_._inline-style ._button-wrapper {
  position: relative;
  margin: 27px 12.5px 0 20px;
}
#_form_172_ ._form-thank-you {
  position: relative;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 18px;
}
@media all and (min-width: 320px) and (max-width: 667px) {
  #_form_172_._inline-form._inline-style ._inline-style._button-wrapper {
    margin-top: 20px !important;
    margin-left: 0 !important;
  }
}
.soumettre {
  background-color: #00a289;
  width: 100%;
}

@media (max-width: 1230px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 900px) {
  .mobilevideo {
    width: 300px;
    height: 300px;
  }
}
@media (max-width: 868px) {
}
@media (max-width: 380px) {
  .mobilevideo {
    width: 200px;
    height: 200px;
  }
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  z-index: 30;
  position: absolute;
  top: 0;
  left: 0;
}
.titrevert {
  margin-top: 0px;
  font-size: 1.5rem;
  color: #00a289 !important;
}
@media (max-width: 1230px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 900px) {
  .titre {
    margin-top: 20px;
    font-size: 1rem;
  }
}
@media (max-width: 868px) {
}
@media (max-width: 380px) {
}

.vert {
  padding: 5px !important;
  width: 50% !important;
  height: auto !important;
  border: 1px solid #00a289 !important;
  border-radius: 5px !important;
}
.clearfixViolet {
  background-color: #7564ac;
  color: white;
}
.clearfixViolet:hover {
  background-color: white;
  color: #7564ac;
}

